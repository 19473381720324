import useGraph from "@/common/composables/useGraph";
import { environment } from "@/common/environments/environmentLoader";
import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  AsyncStatus,
  asyncSucceeded,
} from "@/common/lib/async";
import { convertRequestFormat } from "@/common/lib/derivedV2";
import { FailureType } from "@/common/lib/failure";
import { emptyGraph, Graph } from "@/common/lib/graph";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import { CTMap } from "@/common/lib/map";
import { FetchNProblem, FetchNResponse, GROUP_BY_ALL } from "@/common/lib/query";
import { useFailureStore } from "@/common/stores/failureStore";
import { AxiosResponse } from "axios";
import { isEqual, reject, some, without } from "lodash";
import { defineStore } from "pinia";
import { AskResponse, AskValidationError, buildAskRequest, processAskResponse } from "../lib/ask";
import { ConceptAddress } from "../lib/concept";
import {
  buildExploreTable,
  buildQuery,
  calculateColumnStats,
  ExploreColumn,
  ExploreColumnStats,
  ExploreFilter,
  ExploreGroupBy,
  ExploreOrderBy,
  ExploreTable,
  initialColumnSet,
  initialOrderBy,
  treePathsToNeighborhood,
} from "../lib/explore";
import { allNeighborhoodsInBookmark, ExploreBookmark } from "../lib/exploreBookmark";
import { ExploreTreePath, pathsEquivalent } from "../lib/exploreTree";
import { filterIsComplete } from "../lib/filter";
import { useExploreMetagraphStore } from "./exploreMetagraph";

export enum Mode {
  Table = "table",
  SQL = "sql",
}

export enum Tool {
  Insights = "insights",
  Concept = "concept",
}

export enum ExploreContext {
  Embedded = "embedded",
  Standalone = "standalone",
}

export interface ExploreState {
  columns: ExploreColumn[];
  module?: string;
  root_concept_type?: ConceptKnowledgeRef;
  table: Async<ExploreTable>;
  columnStats: Async<Record<string, ExploreColumnStats>>;
  problems: Async<FetchNProblem[]>;
  order_by: ExploreOrderBy[];
  group_by: ExploreGroupBy[] | typeof GROUP_BY_ALL;
  filters: ExploreFilter[];
  mode: Mode;
  map?: CTMap;
  conceptColors: Record<string, string>;
  metagraph: Graph;
  hideUnusedProperties: boolean;
  expandedPaths: ExploreTreePath[];
  sqlData: Async<string>;
  creatingCalculation?: ExploreTreePath;
  currentLoadTable: Promise<unknown> | undefined;
  currentLoadSql: Promise<unknown> | undefined;
  conceptPage?: ConceptAddress;
  toolsVisible: boolean;
  activeTool: Tool;
  askResponse: Async<AskResponse>;
  showSidebars: () => boolean;
  context: ExploreContext | undefined;
}

export const useExploreStore = defineStore("reader-explore", {
  state: (): ExploreState => ({
    module: undefined,
    root_concept_type: undefined,
    columns: [],
    table: asyncNotStarted(),
    columnStats: asyncNotStarted(),
    problems: asyncNotStarted(),
    order_by: [],
    group_by: [],
    filters: [],
    mode: Mode.Table,
    map: undefined,
    conceptColors: {},
    metagraph: emptyGraph(),
    hideUnusedProperties: false,
    expandedPaths: [],
    sqlData: asyncNotStarted(),
    currentLoadTable: undefined,
    currentLoadSql: undefined,
    creatingCalculation: undefined,
    conceptPage: undefined,
    toolsVisible: false,
    activeTool: Tool.Insights,
    askResponse: asyncNotStarted(),
    showSidebars: () => true,
    context: undefined,
  }),
  getters: {
    columnByAlias(state) {
      return (alias: string) => state.columns.find((col) => col.alias === alias);
    },
    filterByAlias(state) {
      return (alias: string) => state.filters.find((f) => f.alias === alias);
    },
    isPathExpanded(state) {
      return (path: ExploreTreePath) => some(state.expandedPaths, (p) => pathsEquivalent(p, path));
    },
    columnSortState(state) {
      return function (alias: string) {
        const index = state.order_by.findIndex((ob) => ob.on === alias);
        if (index === -1) return undefined;
        return { index, asc: state.order_by[index].asc };
      };
    },
  },
  actions: {
    // I am not thrilled with this imperative cascade/repetition of
    // initialization, and want to look into alternate models. -jstreufert
    boot(module: string) {
      this.module = module;
      this.reset();
    },
    reset() {
      const exploreMetagraphStore = useExploreMetagraphStore();
      this.root_concept_type = undefined;
      this.columns = [];
      this.order_by = [];
      this.group_by = [];
      this.filters = [];
      this.expandedPaths = [];
      this.table = asyncNotStarted();
      this.columnStats = asyncNotStarted();
      this.problems = asyncNotStarted();
      this.sqlData = asyncNotStarted();
      this.mode = Mode.Table;
      this.conceptPage = undefined;
      exploreMetagraphStore.initializeLayout();
      exploreMetagraphStore.visible =
        this.context === ExploreContext.Standalone
          ? environment.requireBoolean("AUTO_SHOW_READER_METAGRAPH")
          : true;
    },
    setMode(mode: Mode) {
      this.mode = mode;
      this.load(false);
    },
    setRootConceptType(conceptType: ConceptKnowledgeRef) {
      this.root_concept_type = conceptType;
      this.order_by = [];
      this.group_by = [];
      this.columns = initialColumnSet();
      this.filters = [];
      this.expandedPaths = [];
      useExploreMetagraphStore().visible = false;
      this.load();
    },
    loadBookmark(bookmark: ExploreBookmark) {
      this.$patch(bookmark);
      if (this.columns.length === 0) this.columns = initialColumnSet();
      useExploreMetagraphStore().visible = false;
      const neighborhoods = allNeighborhoodsInBookmark(bookmark);
      this.expandedPaths = neighborhoods.flatMap((n) => treePathsToNeighborhood(n));
      this.load();
    },
    createBookmark(): ExploreBookmark | undefined {
      if (this.root_concept_type) {
        return {
          root_concept_type: this.root_concept_type,
          filters: this.filters,
          order_by: this.order_by,
          columns: this.columns,
          group_by: this.group_by,
        };
      }
    },
    pivot(conceptType: ConceptKnowledgeRef, filter: ExploreFilter) {
      this.root_concept_type = conceptType;
      this.order_by = [];
      this.group_by = [];
      this.columns = initialColumnSet();
      this.filters = [];
      this.expandedPaths = [];
      this.addFilter(filter); // this load()s
    },
    setOrderBy(order_by?: ExploreOrderBy[]) {
      this.order_by = order_by || [];
      this.load();
    },
    setGroupBy(group_by?: ExploreGroupBy[] | typeof GROUP_BY_ALL) {
      this.group_by = group_by || [];
      this.columns = initialColumnSet();
      this.order_by = initialOrderBy();
      this.load();
    },
    renameColumn(columnAlias: string, newName: string) {
      const column = this.columnByAlias(columnAlias)!;
      column.displayName = newName;
    },
    addColumn(column: ExploreColumn) {
      this.columns = [...this.columns, column];
      this.load();
    },
    removeColumn(columnAlias: string) {
      const column = this.columnByAlias(columnAlias)!;
      this.columns = without(this.columns, column);
      // Remove removed column from order-bys. This will be simplified when aliases are universal.
      this.order_by = reject(
        this.order_by || [],
        (ob) => ob.on === column.property_type || ob.on === column.alias
      );
      this.load();
    },
    addFilter(filter: ExploreFilter, loadIfFilterComplete = true) {
      this.filters.push(filter);
      if (filter.neighborhood != null) {
        for (const path of treePathsToNeighborhood(filter.neighborhood)) {
          if (!this.isPathExpanded(path)) this.expandedPaths.push(path);
        }
      }
      if (loadIfFilterComplete && filterIsComplete(filter)) this.load();
    },
    removeFilter(alias: string) {
      this.filters = reject(this.filters, { alias });
      this.load();
    },
    toggleFilterNegated(alias: string) {
      const filter = this.filterByAlias(alias);
      if (filter != null) {
        filter.negated = !filter.negated;
        this.load();
      }
    },
    togglePathExpanded(path: ExploreTreePath) {
      if (this.isPathExpanded(path)) {
        this.expandedPaths = this.expandedPaths.filter((p) => !isEqual(p, path));
      } else {
        this.expandedPaths.push(path);
      }
    },
    showConceptPage(address: ConceptAddress) {
      this.conceptPage = address;
      this.toolsVisible = true;
      this.activeTool = Tool.Concept;
    },
    async load(reload = true, options?: { testName?: string }) {
      this.conceptPage = undefined;
      if (reload) {
        this.table = asyncInProgress("Loading your data...");
        this.columnStats = asyncInProgress("Loading your data...");
        this.problems = asyncInProgress("Loading your data...");
        this.sqlData = asyncInProgress("Loading your data...");
        this.currentLoadTable = undefined;
        this.currentLoadSql = undefined;
      }
      if (this.mode === Mode.SQL && this.sqlData.status !== AsyncStatus.Succeeded) {
        return await this.loadSql();
      } else if (this.mode === Mode.Table && this.table.status !== AsyncStatus.Succeeded) {
        return await this.loadTable(options);
      }
    },
    async loadTable(options?: { testName?: string }) {
      let response: AxiosResponse<FetchNResponse>;
      const [query, idMap] = buildQuery();
      if (this.map) {
        query.map = this.map;
      }
      if (options?.testName) {
        query.create_named_test = options.testName;
      }
      const queryV2 = convertRequestFormat(query);
      this.table = asyncInProgress("Loading your data...");
      this.columnStats = asyncInProgress("Loading your data...");
      const loadTableResponse = axios.post(`/api/projects/${this.module!}/query`, queryV2);
      try {
        this.currentLoadTable = loadTableResponse;
        response = await loadTableResponse;
        if (loadTableResponse !== this.currentLoadTable) {
          return;
        }
      } catch (error) {
        if (loadTableResponse !== this.currentLoadTable) {
          return;
        }
        this.handleError("Failed to load table", error);
        this.table = asyncFailed("We couldn't load your data.");
        return;
      }

      this.table = asyncSucceeded(buildExploreTable(response.data, idMap));
      this.columnStats = asyncSucceeded(calculateColumnStats());
      this.problems = asyncSucceeded(response.data.problems);
    },
    async loadSql() {
      const [query] = buildQuery();
      if (this.map) {
        query.map = this.map;
      }
      this.sqlData = asyncInProgress("Loading your data...");
      const queryV2 = convertRequestFormat(query);
      const loadSqlResponse = axios.post(`/api/projects/${this.module!}/query?mode=sql`, queryV2);
      try {
        this.currentLoadSql = loadSqlResponse;
        const response: AxiosResponse<string> = await loadSqlResponse;
        if (this.currentLoadSql !== loadSqlResponse) {
          return;
        }
        this.sqlData = asyncSucceeded(response.data);
      } catch (error) {
        if (this.currentLoadSql !== loadSqlResponse) {
          return;
        }
        this.handleError("Failed to load SQL", error);
        this.sqlData = asyncFailed("We couldn't load your data.");
        return;
      }
    },
    async downloadExcel() {
      const [query] = buildQuery();
      if (this.map) {
        query.map = this.map;
      }
      const queryV2 = convertRequestFormat(query);
      const response = await axios.post(`/api/projects/${this.module!}/export/excel`, queryV2, {
        responseType: "blob",
      });
      // Let's create a link in the document that we'll
      // programmatically 'click'.
      const link = document.createElement("a");

      // Tell the browser to associate the response data to
      // the URL of the link we created above.
      link.href = window.URL.createObjectURL(new Blob([response.data]));

      // Tell the browser to download, not render, the file.
      link.setAttribute("download", environment.require("EXCEL_EXPORT_DEFAULT_FILENAME"));

      // Place the link in the DOM.
      document.body.appendChild(link);

      // Make the magic happen!
      link.click();
    },
    async askQuestion(question: string, prevError?: AskValidationError) {
      this.askResponse = asyncInProgress();
      const request = buildAskRequest(question, prevError);
      try {
        const response: AxiosResponse<AskResponse> = await axios.post("/api/llm", request);
        this.askResponse = asyncSucceeded(response.data);
      } catch (error) {
        this.handleError("Failed to interpret your question", error);
        this.askResponse = asyncFailed("Failed to interpret your question");
        return;
      }
      let bookmark: ExploreBookmark;
      try {
        bookmark = processAskResponse(this.askResponse.result);
      } catch (error) {
        if (error instanceof AskValidationError) {
          if (prevError == null) {
            this.askQuestion(question, error); // The LLM gets one more crack at this!
            return;
          } else {
            throw "We couldn't generate a valid query from your question. Please try again, or reword your request.";
          }
        } else {
          throw error;
        }
      }
      this.loadBookmark(bookmark);
    },
    configure(
      context: ExploreContext,
      map: CTMap,
      metagraph: Graph,
      conceptColors: Record<string, string>
    ) {
      // Later we'll do something a little less destructive, comparing the new
      // and old configurations and keeping as much state as possible
      this.context = context;
      this.map = map;
      const { metagraphWithoutRecords } = useGraph(() => metagraph);
      this.metagraph = metagraphWithoutRecords();
      this.conceptColors = conceptColors;
      this.reset();
    },
    handleError(message: string, error: unknown) {
      useFailureStore().backendFail({
        type: FailureType.Explorer,
        description: message,
        error,
        hideUndo: true,
      });
    },
  },
  debounce: {
    loadTable: environment.requireNumber("EXPLORER_DEBOUNCE_MILLISECONDS"),
    loadSql: environment.requireNumber("EXPLORER_DEBOUNCE_MILLISECONDS"),
  },
});
