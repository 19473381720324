import { isBoolean, isNumber } from "lodash";
import { DateTime, Duration } from "luxon";

export enum GraphValueType {
  String = "string",
  Float = "float",
  Integer = "integer",
  Bool = "bool",
  Bytes = "bytes",
  Geopoint = "geopoint",
  Date = "date",
  Time = "time",
  Datetime = "datetime",
  Duration = "duration",
}

export const QUANTITATIVE_VALUE_TYPES = [
  GraphValueType.Float,
  GraphValueType.Integer,
  GraphValueType.Date,
  GraphValueType.Time,
  GraphValueType.Datetime,
  GraphValueType.Duration,
];

export interface StringValue {
  _type: GraphValueType.String;
  value: string;
}

export interface FloatValue {
  _type: GraphValueType.Float;
  value: number;
}

export interface IntegerValue {
  _type: GraphValueType.Integer;
  value: number;
}

export interface BytesValue {
  _type: GraphValueType.Bytes;
  value: string; // This is a hash we can use to request the actual binary resource
  mime_type?: string;
}

export interface BoolValue {
  _type: GraphValueType.Bool;
  value: boolean;
}

export interface GeopointValue {
  _type: GraphValueType.Geopoint;
  value: { lat: number; lon: number; elevation?: number };
}

export interface DateValue {
  _type: GraphValueType.Date;
  value: string; // an ISO 8601 date
}

export interface TimeValue {
  _type: GraphValueType.Time;
  value: string; // an ISO 8601 time
}

export interface DatetimeValue {
  _type: GraphValueType.Datetime;
  value: string; // an ISO 8601 moment in time
}

export interface DurationValue {
  _type: GraphValueType.Duration;
  value: string; // an ISO 8601 duration
}

export type GraphValue =
  | StringValue
  | FloatValue
  | IntegerValue
  | BytesValue
  | BoolValue
  | GeopointValue
  | DateValue
  | TimeValue
  | DatetimeValue
  | DurationValue;

export function isValue(subject: unknown): boolean {
  return subject != null && Object.prototype.hasOwnProperty.call(subject, "_type");
}

export function isMedia(value: GraphValue): boolean {
  return value._type === GraphValueType.Bytes; // XXX lame
}

export function isNumeric(value: GraphValue): boolean {
  return value._type === GraphValueType.Float || value._type === GraphValueType.Integer;
}

export type ConvertableNativeValue = string | number | boolean | DateTime;
export function toValue(nativeValue: ConvertableNativeValue): GraphValue {
  if (isNumber(nativeValue)) {
    return { _type: GraphValueType.Float, value: nativeValue };
  } else if (isBoolean(nativeValue)) {
    return { _type: GraphValueType.Bool, value: nativeValue };
  } else if (nativeValue instanceof DateTime) {
    return { _type: GraphValueType.Datetime, value: nativeValue.toISO() || "" };
  } else {
    return { _type: GraphValueType.String, value: nativeValue };
  }
}

export function stringifyValue(value: GraphValue | null | undefined, empty = "-"): string {
  if (value == null) return empty;
  switch (value._type) {
    case GraphValueType.Integer:
    case GraphValueType.Float:
    case GraphValueType.String:
      return String(value.value);

    case GraphValueType.Bytes:
      if (value.mime_type != null) {
        return `(${value.mime_type})`;
      } else {
        return "(Binary)";
      }
    case GraphValueType.Bool:
      return value.value ? "True" : "False";

    case GraphValueType.Geopoint:
      return `${value.value.lat}, ${value.value.lon}`;

    case GraphValueType.Date:
      return DateTime.fromISO(value.value).toLocaleString(DateTime.DATE_SHORT);

    case GraphValueType.Time:
      return DateTime.fromISO(value.value).toLocaleString(DateTime.TIME_WITH_SECONDS);

    case GraphValueType.Datetime:
      return DateTime.fromISO(value.value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

    case GraphValueType.Duration:
      return Duration.fromISO(value.value).toHuman({ unitDisplay: "short" });
  }
}
