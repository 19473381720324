import {
  cloneDeep,
  filter,
  fromPairs,
  groupBy,
  isArray,
  isEmpty,
  isEqual,
  some,
  uniqWith,
} from "lodash";
import useKnowledge from "../composables/useKnowledge";
import { DocumentPath } from "./document";
import {
  ConceptKnowledgeRef,
  KnowledgeItem,
  PropertyKnowledgeRef,
  PropertyType,
  RECORD_CONCEPT_TYPE,
  splitKnowledgeId,
} from "./knowledge";
import { MapAction } from "./mapActions";
import { GraphValue } from "./value";

export enum MapSectionKey {
  AdhocProperties = "adhoc_properties",
  AdhocConcepts = "adhoc_concepts",
  Import = "import",
  InConnections = "in_connections",
  InEncodings = "in_encodings",
  InTransforms = "in_transforms",
  InIterators = "in_iterators",
  InValueSets = "in_value_sets",
  InValues = "in_values",
  InConcepts = "in_concepts",
  InProperties = "in_properties",
  InPropertyParsers = "in_property_parsers",
  InLinks = "in_links",
  Enrichments = "enrichments",
  Resolutions = "resolutions",
  OutDocuments = "out_documents",
  OutFields = "out_fields",
  OutEncodings = "out_encodings",
  OutConnections = "out_connections",
}

export enum MapLocationType {
  Property = "property",
  PropertyComponent = "property_component",
  PropertyQualifier = "property_qualifier",
  Static = "static",
}

export enum MapValueSetFormat {
  KeyValue = "key_value",
  ValueValue = "value_value",
  Value = "value",
  ValueArray = "value_array",
}

export enum MapValueRole {
  Value = "value",
  Type = "type",
  Component = "component",
  Qualifier = "qualifier",
  Pair = "pair",
}

export enum MapLinkDirection {
  From = "from",
  To = "to",
}

export enum MapFieldType {
  ConceptReference = "concept_reference",
  PropertyValue = "property_value",
}

export interface MapAdhocTypeClause {
  label: string;
  parent: string;
  type: string;
}

export interface MapImportClause {
  map_file: string;
}

export enum SourceType {
  LocalFile = "local_file",
  FromURL = "from_url",
  PostgreSQL = "postgresql",
  DatabricksSql = "dbx_sql",
  Snowflake = "snowflake",
  Unknown = "",
}

interface MapConnectionClause {
  type: SourceType;
  filename?: string;
  url?: string;
  table_name?: string;
  schema_name?: string;
  catalog_name?: string;
  row_limit?: number;
  connection_string?: string;
}

export type MapInConnectionClause = MapConnectionClause;

export interface MapOutConnectionClause extends MapConnectionClause {
  encoders: string[];
}

export interface MapTransformClause {
  type: string;
  depends_on?: string[];
  to_path?: string[];
  from_path?: string[];
  dataset?: string;
  delimiter?: string;
  index?: number;
  patterns?: string[];
  replace_with?: string;
}

export interface MapInEncodingClause {
  type: "read_documents" | "read_in_place_documents";
  container?: string;
  input?: string;
  mime_type?: string;
  exclude_paths?: DocumentPath[];
}

export interface MapOutEncodingClause {
  type: "emit_table_lines" | "emit_sql" | "emit_dynamo";
  projection: string;
  keys?: string[];
  mime_type?: string;
  table_name?: string;
  schema_name?: string;
  catalog_name?: string;
  flavor?: "postgresql" | "snowflake" | "dbx";
}

export interface MapIteratorClause {
  path: DocumentPath;
  dataset: string;
  collection_type: "single" | "array" | "object" | "parallel_arrays";
  parent?: string;
  record_type_signifier?: string;
  parent_record_types?: string[];
}

interface MapBaseValueSetClause {
  iterator: string;
  format: MapValueSetFormat;
  path?: DocumentPath;
}

interface MapKeyValueValueSetClause extends MapBaseValueSetClause {
  format: MapValueSetFormat.KeyValue;
}

interface MapValueValueValueSetClause extends MapBaseValueSetClause {
  format: MapValueSetFormat.ValueValue;
}

interface MapValueValueSetClause extends MapBaseValueSetClause {
  format: MapValueSetFormat.Value;
  property_type: string;
}

interface MapValueArraySetClause extends MapBaseValueSetClause {
  format: MapValueSetFormat.ValueArray;
  property_type: string;
}

export type MapValueSetClause =
  | MapKeyValueValueSetClause
  | MapValueValueValueSetClause
  | MapValueValueSetClause
  | MapValueArraySetClause;

interface MapBaseValueClause {
  value_set: string;
  role: MapValueRole;
  path: DocumentPath;
}

interface MapValueValueClause extends MapBaseValueClause {
  role: MapValueRole.Value;
  property_types?: string[];
}

interface MapTypeValueClause extends MapBaseValueClause {
  role: MapValueRole.Type;
  signifier_name?: string;
}

interface MapComponentValueClause extends MapBaseValueClause {
  role: MapValueRole.Component;
  component_type: string;
  property_types?: string[];
}

interface MapQualifierValueClause extends MapBaseValueClause {
  role: MapValueRole.Qualifier;
  qualifier_type: string;
  property_types?: string[];
}

interface MapPairValueClause extends MapBaseValueClause {
  role: MapValueRole.Pair;
  property_type?: string;
}

export type MapValueClause =
  | MapValueValueClause
  | MapTypeValueClause
  | MapComponentValueClause
  | MapQualifierValueClause
  | MapPairValueClause;

export interface MapConceptClause {
  concept_type: ConceptKnowledgeRef;
  source_iterator: string;
  source_record_type?: string;
  source_property_type?: string;
}

export interface MapLinkClause {
  link_type: string;
  from_concept: string;
  to_concept: string;
}

export interface MapPropertyClause {
  property_type: PropertyKnowledgeRef;
  on_concept: string;
  values: Record<string, MapLocation>;
  qualifiers?: Record<string, MapLocation>;
}

export interface MapPropertyParserClause {
  on_property: string;
  parser_type?: string; // No parser_type means use the implicit parser
  params: Record<string, string>;
}

export interface MapResolutionClause {
  type: ConceptKnowledgeRef;
  on: PropertyKnowledgeRef[];
}

export interface MapEnrichmentClause {
  type: string;
  signature: string;
  depends_on?: string[];
}

interface MapDocumentClauseForConcept {
  concept_type: string;
  link_type?: never;
}

interface MapDocumentClauseForLink {
  concept_type?: never;
  link_type: string;
}

export type MapDocumentClause = MapDocumentClauseForConcept | MapDocumentClauseForLink;

export interface MapFieldClause {
  on_document: string;
  document_path: DocumentPath;
  type: MapFieldType;
  graph_path?: string[];
  property_type?: string;
  generator?: string;
  generator_output?: string;
  link_direction?: MapLinkDirection;
}

export type MapSection<T> = Record<string, T>;

export interface MapSectionToClauseType {
  [MapSectionKey.AdhocProperties]: MapAdhocTypeClause;
  [MapSectionKey.AdhocConcepts]: MapAdhocTypeClause;
  [MapSectionKey.Import]: MapImportClause;
  [MapSectionKey.InConnections]: MapInConnectionClause;
  [MapSectionKey.InEncodings]: MapInEncodingClause;
  [MapSectionKey.InTransforms]: MapTransformClause;
  [MapSectionKey.InIterators]: MapIteratorClause;
  [MapSectionKey.InValueSets]: MapValueSetClause;
  [MapSectionKey.InValues]: MapValueClause;
  [MapSectionKey.InConcepts]: MapConceptClause;
  [MapSectionKey.InProperties]: MapPropertyClause;
  [MapSectionKey.InPropertyParsers]: MapPropertyParserClause;
  [MapSectionKey.InLinks]: MapLinkClause;
  [MapSectionKey.Enrichments]: MapEnrichmentClause;
  [MapSectionKey.Resolutions]: MapResolutionClause;
  [MapSectionKey.OutDocuments]: MapDocumentClause;
  [MapSectionKey.OutFields]: MapFieldClause;
  [MapSectionKey.OutEncodings]: MapOutEncodingClause;
  [MapSectionKey.OutConnections]: MapOutConnectionClause;
}

export type CTMap = {
  [Property in keyof MapSectionToClauseType]?: MapSection<MapSectionToClauseType[Property]>;
};

export type MapClause = MapSectionToClauseType[keyof MapSectionToClauseType];

export interface MapStaticLocation {
  location: MapLocationType.Static;
  values: GraphValue[];
  repeat?: boolean;
}

export interface MapPropertyLocation {
  location: MapLocationType.Property;
  property_type: string;
  parent_iterator?: string;
  repeat?: boolean;
}

export interface MapPropertyComponentLocation {
  location: MapLocationType.PropertyComponent;
  property_type: string;
  component_type: string;
  parent_iterator?: string;
  repeat?: boolean;
}

export interface MapPropertyQualifierLocation {
  location: MapLocationType.PropertyQualifier;
  property_type: string;
  qualifier_type: string;
  parent_iterator?: string;
  repeat?: boolean;
}

export type MapLocation =
  | MapStaticLocation
  | MapPropertyLocation
  | MapPropertyComponentLocation
  | MapPropertyQualifierLocation;

export type MapClauseReference = [MapSectionKey, string];

export type ConceptPropertyPair = [ConceptKnowledgeRef, PropertyKnowledgeRef];

export function refSection(ref: MapClauseReference): MapSectionKey {
  return ref[0];
}

export function refClause(ref: MapClauseReference): string {
  return ref[1];
}

export function getMapSection<T extends MapSectionKey>(map: CTMap, section: T) {
  return (map[section] || {}) as MapSection<MapSectionToClauseType[T]>;
}

export function getMapClause<T extends MapSectionKey>(
  map: CTMap,
  section: T,
  clause: string
): MapSectionToClauseType[T] {
  return getMapSection(map, section)[clause];
}

export function getMapClausesWhere<T extends MapSectionKey>(
  map: CTMap,
  section: T,
  filterFn: (clause: MapSectionToClauseType[T]) => boolean
): MapSection<MapSectionToClauseType[T]> {
  return fromPairs(Object.entries(getMapSection(map, section)).filter(([, v]) => filterFn(v)));
}

export function locationsForPropertyClause(clause: MapPropertyClause): MapLocation[] {
  return [...Object.values(clause.values), ...Object.values(clause.qualifiers || {})];
}

// Returns the keys of the transform clauses leading to a data path
export function transformLineageForDataPath(map: CTMap, dataset: string, path: string[]): string[] {
  const transforms = map[MapSectionKey.InTransforms] || {};
  function getLineage(path?: string[], candidates?: string[]): string[] {
    if (path == null) return [];
    for (const [key, clause] of Object.entries(transforms)) {
      if (clause.dataset === dataset && isEqual(clause.to_path, path)) {
        if (!isArray(candidates) || candidates.includes(key)) {
          return [...getLineage(clause.from_path, clause.depends_on || []), key];
        }
      }
    }
    return [];
  }
  return getLineage(path);
}

export function extractMapKnowledge(
  map: CTMap,
  getKnowledgeItem: (id: string) => KnowledgeItem
): Record<string, KnowledgeItem> {
  const knowledge: Record<string, KnowledgeItem> = {};
  const adHocClauses = [
    ...Object.values(getMapSection(map, MapSectionKey.AdhocProperties)),
    ...Object.values(getMapSection(map, MapSectionKey.AdhocConcepts)),
  ];
  for (const adHocClause of adHocClauses) {
    const { name, group } = splitKnowledgeId(adHocClause.type);
    const adhocItem: KnowledgeItem = {
      ...getKnowledgeItem(adHocClause.parent),
      name,
      group,
      label: adHocClause.label,
      parent: adHocClause.parent,
      adhoc: true,
    };
    knowledge[adHocClause.type] = adhocItem;
  }
  return knowledge;
}

export function hasResolutionClauseForTypes(map: CTMap, conceptType: string, propertyType: string) {
  const clauses = Object.values(getMapSection(map, MapSectionKey.Resolutions));
  return some(
    clauses,
    (c) => c.type === conceptType && c.on.length === 1 && c.on[0] === propertyType
  );
}

export function unplannedMapClauses(map: CTMap): MapClauseReference[] {
  // Enumerates map clauses that are missing dependents and could thus use help from the planner
  const clauses: MapClauseReference[] = [];
  // Any decoders without iterators (i.e. newly added inputs?)
  const iterators = Object.values(getMapSection(map, MapSectionKey.InIterators));
  for (const decoderKey of Object.keys(getMapSection(map, MapSectionKey.InEncodings))) {
    if (!iterators.find((i) => i.dataset === decoderKey)) {
      clauses.push([MapSectionKey.InEncodings, decoderKey]);
    }
  }
  // Any properties without parsers?
  const parsers = Object.values(getMapSection(map, MapSectionKey.InPropertyParsers));
  for (const [pKey, pClause] of Object.entries(getMapSection(map, MapSectionKey.InProperties))) {
    if (!isEmpty(pClause.values) && !parsers.find((parser) => parser.on_property === pKey)) {
      clauses.push([MapSectionKey.InProperties, pKey]);
    }
  }
  return clauses;
}

export function immediateDependentsOfClause(
  map: CTMap,
  clause: MapClauseReference
): MapClauseReference[] {
  // Given a map and a clause reference, returns clause references for each clause that directly
  // depends on it (i.e. would no longer be valid if the given clause were removed)
  const [sectionKey, clauseKey] = clause;
  switch (sectionKey) {
    case MapSectionKey.InConnections:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.InEncodings, (e) => e.input === clauseKey)
      ).map((k) => [MapSectionKey.InEncodings, k]);
    case MapSectionKey.InEncodings: {
      const encodings: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InEncodings, (e) => e.container === clauseKey)
      ).map((k) => [MapSectionKey.InEncodings, k]);
      const iterators: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InIterators, (i) => i.dataset === clauseKey)
      ).map((k) => [MapSectionKey.InIterators, k]);
      const transforms: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InTransforms, (t) => t.dataset === clauseKey)
      ).map((k) => [MapSectionKey.InTransforms, k]);
      return [...encodings, ...iterators, ...transforms];
    }
    case MapSectionKey.InTransforms:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.InTransforms, (t) =>
          (t.depends_on ?? []).includes(clauseKey)
        )
      ).map((k) => [MapSectionKey.InTransforms, k]);
    case MapSectionKey.InIterators: {
      const valSets: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InValueSets, (v) => v.iterator === clauseKey)
      ).map((k) => [MapSectionKey.InValueSets, k]);
      const concepts: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InConcepts, (c) => c.source_iterator === clauseKey)
      ).map((k) => [MapSectionKey.InConcepts, k]);
      return [...valSets, ...concepts];
    }
    case MapSectionKey.InValueSets:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.InValues, (v) => v.value_set === clauseKey)
      ).map((k) => [MapSectionKey.InValueSets, k]);
    case MapSectionKey.InConcepts: {
      const props: MapClauseReference[] = Object.keys(
        getMapClausesWhere(map, MapSectionKey.InProperties, (p) => p.on_concept === clauseKey)
      ).map((k) => [MapSectionKey.InProperties, k]);
      const links: MapClauseReference[] = Object.keys(
        getMapClausesWhere(
          map,
          MapSectionKey.InLinks,
          (l) => l.from_concept === clauseKey || l.to_concept === clauseKey
        )
      ).map((k) => [MapSectionKey.InLinks, k]);
      return [...props, ...links];
    }
    case MapSectionKey.OutDocuments:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.OutFields, (f) => f.on_document === clauseKey)
      ).map((k) => [MapSectionKey.OutFields, k]);
    case MapSectionKey.Enrichments:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.Enrichments, (e) =>
          (e.depends_on ?? []).includes(clauseKey)
        )
      ).map((k) => [MapSectionKey.Enrichments, k]);
    case MapSectionKey.InProperties:
      return Object.keys(
        getMapClausesWhere(map, MapSectionKey.InPropertyParsers, (p) => p.on_property === clauseKey)
      ).map((k) => [MapSectionKey.InPropertyParsers, k]);
    case MapSectionKey.InPropertyParsers:
    case MapSectionKey.InValues:
    case MapSectionKey.InLinks:
    case MapSectionKey.OutFields:
    case MapSectionKey.Resolutions:
      return [];
    default: {
      throw `immediateDependentsOfClause not implemented for ${sectionKey}`;
    }
  }
}

export function allDependentsOfClause(
  map: CTMap,
  clause: MapClauseReference
): MapClauseReference[] {
  const dependents: MapClauseReference[] = [];
  const unexplored = [clause];
  while (unexplored.length > 0) {
    const newDeps = immediateDependentsOfClause(map, unexplored.pop()!);
    dependents.push(...newDeps);
    unexplored.push(...newDeps);
  }
  return dependents;
}

export function selectivelyApplyMap(
  baseMap: CTMap,
  overlayMap: CTMap,
  parentClauses: MapClauseReference[]
) {
  const map = cloneDeep(baseMap);
  map[MapSectionKey.AdhocConcepts] ||= {};
  map[MapSectionKey.AdhocProperties] ||= {};
  for (const parentClause of parentClauses) {
    for (const [sectionKey, clauseKey] of allDependentsOfClause(overlayMap, parentClause)) {
      const clause = getMapClause(overlayMap, sectionKey, clauseKey);
      map[sectionKey] ||= {};
      map[sectionKey]![clauseKey] = clause;
      // If the new clause references ad-hoc knowledge, include that also.
      if (sectionKey === MapSectionKey.InProperties) {
        const adhocs = getMapClausesWhere(
          overlayMap,
          MapSectionKey.AdhocProperties,
          (k) => k.type === (clause as MapPropertyClause).property_type
        );
        for (const [k, c] of Object.entries(adhocs)) map[MapSectionKey.AdhocProperties]![k] = c;
      } else if (sectionKey === MapSectionKey.InConcepts) {
        const adhocs = getMapClausesWhere(
          overlayMap,
          MapSectionKey.AdhocConcepts,
          (k) => k.type === (clause as MapConceptClause).concept_type
        );
        for (const [k, c] of Object.entries(adhocs)) map[MapSectionKey.AdhocConcepts]![k] = c;
      }
    }
  }
  return map;
}

export function garbageCollectMap(originalMap: CTMap): CTMap {
  const deleteList: MapClauseReference[] = [];
  const map = cloneDeep(originalMap);
  const adhocCons = Object.entries(getMapSection(map, MapSectionKey.AdhocConcepts));
  const adhocProps = Object.entries(getMapSection(map, MapSectionKey.AdhocProperties));
  const props = getMapSection(map, MapSectionKey.InProperties);
  const cons = getMapSection(map, MapSectionKey.InConcepts);
  // Remove empty record concept mappings
  for (const [key, clause] of Object.entries(getMapSection(map, MapSectionKey.InConcepts))) {
    if (some(props, (pc) => pc.on_concept === key)) continue;
    const adhocType = adhocCons.find(([, ahc]) => ahc.type === clause.concept_type);
    if (adhocType?.[1].parent === RECORD_CONCEPT_TYPE) {
      const clauseRef: MapClauseReference = [MapSectionKey.InConcepts, key];
      deleteList.push(...allDependentsOfClause(map, clauseRef));
      deleteList.push(clauseRef);
    }
  }
  // Remove unused ad-hoc property types
  const propTypesInUse = Object.values(props).map((pc) => pc.property_type);
  for (const [key, clause] of adhocProps) {
    if (!propTypesInUse.includes(clause.type as PropertyKnowledgeRef))
      deleteList.push([MapSectionKey.AdhocProperties, key]);
  }
  // Remove unused ad-hoc concept types
  const conTypesInUse = Object.values(cons).map((cc) => cc.concept_type);
  for (const [key, clause] of adhocCons) {
    if (!conTypesInUse.includes(clause.type as ConceptKnowledgeRef))
      deleteList.push([MapSectionKey.AdhocConcepts, key]);
  }

  for (const [sectionKey, clauseKey] of deleteList) delete map[sectionKey]![clauseKey];
  return map;
}

export function applyAutomerge(map: CTMap, banned: ConceptPropertyPair[]): CTMap {
  const { getKnowledgeItem, isRecordConceptType } = useKnowledge();
  const mergeCandidates: ConceptPropertyPair[] = [];
  const mergeClauses = Object.values(getMapSection(map, MapSectionKey.Resolutions));
  const alreadyMerged = mergeClauses.map((r) => r.type);
  // Find automergable, valid, non-banned prop mappings on non-record concepts
  // that aren't already merged
  for (const [pkey, prop] of Object.entries(getMapSection(map, MapSectionKey.InProperties))) {
    const propType = getKnowledgeItem(prop.property_type) as PropertyType;
    const concept = getMapClause(map, MapSectionKey.InConcepts, prop.on_concept);
    const parsers = Object.values(
      getMapClausesWhere(map, MapSectionKey.InPropertyParsers, (p) => p.on_property === pkey)
    );
    const pair: ConceptPropertyPair = [concept.concept_type, prop.property_type];
    if (
      propType.automerge &&
      !banned.find((b) => isEqual(b, pair)) &&
      !alreadyMerged.includes(concept.concept_type) &&
      parsers.length === 1 &&
      !isRecordConceptType(concept.concept_type)
    ) {
      mergeCandidates.push(pair);
    }
  }
  // Find concepts with exactly one candidate property, and merge those candidates
  const toMerge = filter(
    groupBy(uniqWith(mergeCandidates, isEqual), (pair) => pair[0]),
    (group) => group.length === 1
  ).flat(1);
  const action = new MapAction(map);
  for (const [conceptType, propertyType] of toMerge) {
    action.addResolution(conceptType, [propertyType]);
  }
  return action.map;
}
